import React, { useState } from "react"
import { Link } from "gatsby"

import { Tabs } from 'antd';

import Layout from "../components/layout"
import SEO from "../components/seo"

import ContactForm from "../components/ContactForm"


import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox"

//Images
import Maserati_prez from "../images/images/maserati/maserati-damenuhren.jpg"

import Maserati_m_001 from "../images/images/maserati/men/maserati_m_001.jpg"
import Maserati_m_002 from "../images/images/maserati/men/maserati_m_002.jpg"
import Maserati_m_003 from "../images/images/maserati/men/maserati_m_003.jpg"
import Maserati_m_004 from "../images/images/maserati/men/maserati_m_004.jpg"
import Maserati_m_005 from "../images/images/maserati/men/maserati_m_005.jpg"
import Maserati_m_006 from "../images/images/maserati/men/maserati_m_006.jpg"
import Maserati_m_007 from "../images/images/maserati/men/maserati_m_007.jpg"
import Maserati_m_008 from "../images/images/maserati/men/maserati_m_008.jpg"
import Maserati_m_009 from "../images/images/maserati/men/maserati_m_009.jpg"
import Maserati_m_010 from "../images/images/maserati/men/maserati_m_010.jpg"
import Maserati_m_011 from "../images/images/maserati/men/maserati_m_011.jpg"
import Maserati_m_012 from "../images/images/maserati/men/maserati_m_012.jpg"
import Maserati_m_013 from "../images/images/maserati/men/maserati_m_013.jpg"
import Maserati_m_014 from "../images/images/maserati/men/maserati_m_014.jpg"
import Maserati_m_015 from "../images/images/maserati/men/maserati_m_015.jpg"
import Maserati_m_016 from "../images/images/maserati/men/maserati_m_016.jpg"
import Maserati_m_017 from "../images/images/maserati/men/maserati_m_017.jpg"
import Maserati_m_018 from "../images/images/maserati/men/maserati_m_018.jpg"
import Maserati_m_019 from "../images/images/maserati/men/maserati_m_019.jpg"
import Maserati_m_020 from "../images/images/maserati/men/maserati_m_020.jpg"
import Maserati_m_021 from "../images/images/maserati/men/maserati_m_021.jpg"
import Maserati_m_022 from "../images/images/maserati/men/maserati_m_022.jpg"
import Maserati_m_023 from "../images/images/maserati/men/maserati_m_023.jpg"
import Maserati_m_024 from "../images/images/maserati/men/maserati_m_024.jpg"
import Maserati_m_025 from "../images/images/maserati/men/maserati_m_025.jpg"
import Maserati_m_026 from "../images/images/maserati/men/maserati_m_026.jpg"
import Maserati_m_027 from "../images/images/maserati/men/maserati_m_027.jpg"
import Maserati_m_028 from "../images/images/maserati/men/maserati_m_028.jpg"
import Maserati_m_029 from "../images/images/maserati/men/maserati_m_029.jpg"
import Maserati_m_030 from "../images/images/maserati/men/maserati_m_030.jpg"
import Maserati_m_031 from "../images/images/maserati/men/maserati_m_031.jpg"
import Maserati_m_032 from "../images/images/maserati/men/maserati_m_032.jpg"

import Maserati_w_001 from "../images/images/maserati/women/maserati_w_001.jpg"
import Maserati_w_002 from "../images/images/maserati/women/maserati_w_002.jpg"
import Maserati_w_003 from "../images/images/maserati/women/maserati_w_003.jpg"
import Maserati_w_004 from "../images/images/maserati/women/maserati_w_004.jpg"
import Maserati_w_005 from "../images/images/maserati/women/maserati_w_005.jpg"
import Maserati_w_006 from "../images/images/maserati/women/maserati_w_006.jpg"
import Maserati_w_007 from "../images/images/maserati/women/maserati_w_007.jpg"
import Maserati_w_008 from "../images/images/maserati/women/maserati_w_008.jpg"
import Maserati_w_009 from "../images/images/maserati/women/maserati_w_009.jpg"
import Maserati_w_010 from "../images/images/maserati/women/maserati_w_010.jpg"
import Maserati_w_011 from "../images/images/maserati/women/maserati_w_011.jpg"
import Maserati_w_012 from "../images/images/maserati/women/maserati_w_012.jpg"
import Maserati_w_013 from "../images/images/maserati/women/maserati_w_013.jpg"
import Maserati_w_014 from "../images/images/maserati/women/maserati_w_014.jpg"
import Maserati_w_015 from "../images/images/maserati/women/maserati_w_015.jpg"
import Maserati_w_016 from "../images/images/maserati/women/maserati_w_016.jpg"

import { Button, Input, Modal, Tag } from "antd"


const options = {
  overlayColor: "rgba(44,47,76,0.9)",
  captionStyle: {
    captionColor: "#857e74",
    captionFontFamily: "Raleway, sans-serif",
    captionFontSize: "22px",
    captionFontWeight: "300",
    captionFontStyle: "capitalize",
  },
  buttonsStyle: {
    buttonsBackgroundColor: "#857e74",
    buttonsIconColor: "rgba(176,158,111,0.8)",
  },
  autoplaySpeed: 1500,
  transitionSpeed: 0,
  showCaption: false,
  showThumbnails: false,
}

const Horlogeries_yvan_monet = () => {

  const { TabPane } = Tabs;

  //Hooks
  const [dataContact, setDataContact] = useState(false)

  //Modal
  const [showModalContact, setShowModalContact] = useState(false)

  const showModalContactForm = (value_i) => {
    setShowModalContact(true)
    setDataContact(value_i)
  }

  const handleContactFormOk = e => {
    setShowModalContact(false)
  }

  const handleContactFormCancel = e => {
    setShowModalContact(false)
  }

  return (
    <>
      <Layout>
        <SEO title="Montres Maserati"/>

        <div className="slideshow">
          <div className="usp">
            <div className="usp-txt-header">Maserati</div>
          </div>
        </div>
        <div className="border-gold-bottom"></div>


        <section>

          <div className="section-flex-01" style={{ margin: "0px" }}>

            <h2 className="h2-mobile"  style={{textAlign: 'center'}}>Maserati</h2>

            <div style={{ display: "flex", flexDirection: "column" }}>
              <div className="watch-intro">
                <img className="img" style={{ margin: "0px", marginTop: "6%", borderRadius: "12px" }} src={Maserati_prez} alt="Montres Maserati"/>
                <div>
                  <p  className="p-2 p-font" style={{paddingBottom: 0, marginBottom: 0}}>
                    La perfection technique et l'attention apportée aux détails par Maserati pour chacune de ses voitures se reflètent dans des chronographes au style incomparable, qui reprennent sous la forme de créations originales et élégantes les éléments les plus emblématiques de l'histoire et du design de la marque au Trident.
                  </p>

                  <p  className="p-2 p-font" style={{paddingBottom: 0, marginBottom: 0}}>Un grand choix de montres pour tous les styles.</p>
                  <p  className="p-2 p-font" style={{paddingTop: 0, marginTop: 0}}>Retrouvez la dernière collection dans nos boutiques.</p>
                </div>
              </div>
              <SimpleReactLightbox>
                <div className="h4-2">Homme</div>
                <SRLWrapper options={options}>
                  <div id="gallery-with-links" className="container content content-gallery">
                    <div className="img-container col-image-with-link">
                      <a href={Maserati_m_001} data-attribute="SRL"><img className="img-gallery" src={Maserati_m_001}
                                                                         alt="Maserati"/></a>
                    </div>
                    <div className="img-container col-image-with-link">
                      <a href={Maserati_m_002} data-attribute="SRL"><img className="img-gallery" src={Maserati_m_002}
                                                                         alt="Maserati"/></a>
                    </div>
                    <div className="img-container col-image-with-link">
                      <a href={Maserati_m_003} data-attribute="SRL"><img className="img-gallery" src={Maserati_m_003}
                                                                         alt="Maserati"/></a>
                    </div>
                    <div className="img-container col-image-with-link">
                      <a href={Maserati_m_004} data-attribute="SRL"><img className="img-gallery" src={Maserati_m_004}
                                                                         alt="Maserati"/></a>
                    </div>
                    <div className="img-container col-image-with-link">
                      <a href={Maserati_m_005} data-attribute="SRL"><img className="img-gallery" src={Maserati_m_005}
                                                                         alt="Maserati"/></a>
                    </div>
                    <div className="img-container col-image-with-link">
                      <a href={Maserati_m_006} data-attribute="SRL"><img className="img-gallery" src={Maserati_m_006}
                                                                         alt="Maserati"/></a>
                    </div>
                    <div className="img-container col-image-with-link">
                      <a href={Maserati_m_007} data-attribute="SRL"><img className="img-gallery" src={Maserati_m_007}
                                                                         alt="Maserati"/></a>
                    </div>
                    <div className="img-container col-image-with-link">
                      <a href={Maserati_m_008} data-attribute="SRL"><img className="img-gallery" src={Maserati_m_008}
                                                                         alt="Maserati"/></a>
                    </div>
                    <div className="img-container col-image-with-link">
                      <a href={Maserati_m_009} data-attribute="SRL"><img className="img-gallery" src={Maserati_m_009}
                                                                         alt="Maserati"/></a>
                    </div>
                    <div className="img-container col-image-with-link">
                      <a href={Maserati_m_010} data-attribute="SRL"><img className="img-gallery" src={Maserati_m_010}
                                                                         alt="Maserati"/></a>
                    </div>
                    <div className="img-container col-image-with-link">
                      <a href={Maserati_m_011} data-attribute="SRL"><img className="img-gallery" src={Maserati_m_011}
                                                                         alt="Maserati"/></a>
                    </div>
                    <div className="img-container col-image-with-link">
                      <a href={Maserati_m_012} data-attribute="SRL"><img className="img-gallery" src={Maserati_m_012}
                                                                         alt="Maserati"/></a>
                    </div>
                    <div className="img-container col-image-with-link">
                      <a href={Maserati_m_013} data-attribute="SRL"><img className="img-gallery" src={Maserati_m_013}
                                                                         alt="Maserati"/></a>
                    </div>
                    <div className="img-container col-image-with-link">
                      <a href={Maserati_m_014} data-attribute="SRL"><img className="img-gallery" src={Maserati_m_014}
                                                                         alt="Maserati"/></a>
                    </div>
                    <div className="img-container col-image-with-link">
                      <a href={Maserati_m_015} data-attribute="SRL"><img className="img-gallery" src={Maserati_m_015}
                                                                         alt="Maserati"/></a>
                    </div>
                    <div className="img-container col-image-with-link">
                      <a href={Maserati_m_016} data-attribute="SRL"><img className="img-gallery" src={Maserati_m_016}
                                                                         alt="Maserati"/></a>
                    </div>
                    <div className="img-container col-image-with-link">
                      <a href={Maserati_m_017} data-attribute="SRL"><img className="img-gallery" src={Maserati_m_017}
                                                                         alt="Maserati"/></a>
                    </div>
                    <div className="img-container col-image-with-link">
                      <a href={Maserati_m_018} data-attribute="SRL"><img className="img-gallery" src={Maserati_m_018}
                                                                         alt="Maserati"/></a>
                    </div>
                    <div className="img-container col-image-with-link">
                      <a href={Maserati_m_019} data-attribute="SRL"><img className="img-gallery" src={Maserati_m_019}
                                                                         alt="Maserati"/></a>
                    </div>
                    <div className="img-container col-image-with-link">
                      <a href={Maserati_m_020} data-attribute="SRL"><img className="img-gallery" src={Maserati_m_020}
                                                                         alt="Maserati"/></a>
                    </div>
                    <div className="img-container col-image-with-link">
                      <a href={Maserati_m_021} data-attribute="SRL"><img className="img-gallery" src={Maserati_m_021}
                                                                         alt="Maserati"/></a>
                    </div>
                    <div className="img-container col-image-with-link">
                      <a href={Maserati_m_022} data-attribute="SRL"><img className="img-gallery" src={Maserati_m_022}
                                                                         alt="Maserati"/></a>
                    </div>
                    <div className="img-container col-image-with-link">
                      <a href={Maserati_m_023} data-attribute="SRL"><img className="img-gallery" src={Maserati_m_023}
                                                                         alt="Maserati"/></a>
                    </div>
                    <div className="img-container col-image-with-link">
                      <a href={Maserati_m_024} data-attribute="SRL"><img className="img-gallery" src={Maserati_m_024}
                                                                         alt="Maserati"/></a>
                    </div>
                    <div className="img-container col-image-with-link">
                      <a href={Maserati_m_025} data-attribute="SRL"><img className="img-gallery" src={Maserati_m_025}
                                                                         alt="Maserati"/></a>
                    </div>
                    <div className="img-container col-image-with-link">
                      <a href={Maserati_m_026} data-attribute="SRL"><img className="img-gallery" src={Maserati_m_026}
                                                                         alt="Maserati"/></a>
                    </div>
                    <div className="img-container col-image-with-link">
                      <a href={Maserati_m_027} data-attribute="SRL"><img className="img-gallery" src={Maserati_m_027}
                                                                         alt="Maserati"/></a>
                    </div>
                    <div className="img-container col-image-with-link">
                      <a href={Maserati_m_028} data-attribute="SRL"><img className="img-gallery" src={Maserati_m_028}
                                                                         alt="Maserati"/></a>
                    </div>
                    <div className="img-container col-image-with-link">
                      <a href={Maserati_m_029} data-attribute="SRL"><img className="img-gallery" src={Maserati_m_029}
                                                                         alt="Maserati"/></a>
                    </div>
                    <div className="img-container col-image-with-link">
                      <a href={Maserati_m_030} data-attribute="SRL"><img className="img-gallery" src={Maserati_m_030}
                                                                         alt="Maserati"/></a>
                    </div>
                    <div className="img-container col-image-with-link">
                      <a href={Maserati_m_031} data-attribute="SRL"><img className="img-gallery" src={Maserati_m_031}
                                                                         alt="Maserati"/></a>
                    </div>
                    <div className="img-container col-image-with-link">
                      <a href={Maserati_m_032} data-attribute="SRL"><img className="img-gallery" src={Maserati_m_032}
                                                                         alt="Maserati"/></a>
                    </div>
                  </div>
                </SRLWrapper>
              </SimpleReactLightbox>
              <SimpleReactLightbox>
                <div className="h4-2">Femme</div>
                <SRLWrapper options={options}>
                  <div id="gallery-with-links" className="container content content-gallery">
                    <div className="img-container col-image-with-link">
                      <a href={Maserati_w_001} data-attribute="SRL"><img className="img-gallery" src={Maserati_w_001}
                                                                         alt="Maserati"/></a>
                    </div>
                    <div className="img-container col-image-with-link">
                      <a href={Maserati_w_002} data-attribute="SRL"><img className="img-gallery" src={Maserati_w_002}
                                                                         alt="Maserati"/></a>
                    </div>
                    <div className="img-container col-image-with-link">
                      <a href={Maserati_w_003} data-attribute="SRL"><img className="img-gallery" src={Maserati_w_003}
                                                                         alt="Maserati"/></a>
                    </div>
                    <div className="img-container col-image-with-link">
                      <a href={Maserati_w_004} data-attribute="SRL"><img className="img-gallery" src={Maserati_w_004}
                                                                         alt="Maserati"/></a>
                    </div>
                    <div className="img-container col-image-with-link">
                      <a href={Maserati_w_005} data-attribute="SRL"><img className="img-gallery" src={Maserati_w_005}
                                                                         alt="Maserati"/></a>
                    </div>
                    <div className="img-container col-image-with-link">
                      <a href={Maserati_w_006} data-attribute="SRL"><img className="img-gallery" src={Maserati_w_006}
                                                                         alt="Maserati"/></a>
                    </div>
                    <div className="img-container col-image-with-link">
                      <a href={Maserati_w_007} data-attribute="SRL"><img className="img-gallery" src={Maserati_w_007}
                                                                         alt="Maserati"/></a>
                    </div>
                    <div className="img-container col-image-with-link">
                      <a href={Maserati_w_008} data-attribute="SRL"><img className="img-gallery" src={Maserati_w_008}
                                                                         alt="Maserati"/></a>
                    </div>
                    <div className="img-container col-image-with-link">
                      <a href={Maserati_w_009} data-attribute="SRL"><img className="img-gallery" src={Maserati_w_009}
                                                                         alt="Maserati"/></a>
                    </div>
                    <div className="img-container col-image-with-link">
                      <a href={Maserati_w_010} data-attribute="SRL"><img className="img-gallery" src={Maserati_w_010}
                                                                         alt="Maserati"/></a>
                    </div>
                    <div className="img-container col-image-with-link">
                      <a href={Maserati_w_011} data-attribute="SRL"><img className="img-gallery" src={Maserati_w_011}
                                                                         alt="Maserati"/></a>
                    </div>
                    <div className="img-container col-image-with-link">
                      <a href={Maserati_w_012} data-attribute="SRL"><img className="img-gallery" src={Maserati_w_012}
                                                                         alt="Maserati"/></a>
                    </div>
                    <div className="img-container col-image-with-link">
                      <a href={Maserati_w_013} data-attribute="SRL"><img className="img-gallery" src={Maserati_w_013}
                                                                         alt="Maserati"/></a>
                    </div>
                    <div className="img-container col-image-with-link">
                      <a href={Maserati_w_014} data-attribute="SRL"><img className="img-gallery" src={Maserati_w_014}
                                                                         alt="Maserati"/></a>
                    </div>
                    <div className="img-container col-image-with-link">
                      <a href={Maserati_w_015} data-attribute="SRL"><img className="img-gallery" src={Maserati_w_015}
                                                                         alt="Maserati"/></a>
                    </div>
                    <div className="img-container col-image-with-link">
                      <a href={Maserati_w_016} data-attribute="SRL"><img className="img-gallery" src={Maserati_w_016}
                                                                         alt="Maserati"/></a>
                    </div>
                  </div>
                </SRLWrapper>
              </SimpleReactLightbox>
              <div style={{display: 'flex', justifyContent: 'center'}}><Button className="button-gold-box btn-horlogeries" onClick={ () => showModalContactForm('Maserati') }>Nous contacter</Button></div>
            </div>

          </div>
        </section>

        <Modal title="Formulaire de contact"
        style={{minWidth: '40vw', height: '80vh'}}
          visible={ showModalContact }
          onOk={ handleContactFormOk }
          onCancel={ handleContactFormCancel }
          okButtonProps={{ style: { display: 'none' } }}
        >

          <ContactForm page={dataContact} />

        </Modal>

      </Layout>
    </>
  )
}

export default Horlogeries_yvan_monet
